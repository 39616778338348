import React, { Component } from 'react'

import HeaderRightSvg from '../../components/public_components/svg/HeaderRightSvg'
import { Link } from 'react-router-dom'
import SectionHeader from '../../components/public_components/SectionHeader'
import Card1 from '../../components/public_components/cards/Card1'
import Card2 from '../../components/public_components/cards/Card2'
import Card3 from '../../components/public_components/cards/Card3'
import Card4 from '../../components/public_components/cards/Card4'
import SendMailSvg from '../../components/public_components/svg/SendMailSvg'
import MainHeaderSection from './sections/MainHeaderSection'
import StudentsSection from './sections/StudentsSection'
import CountriesSection from './sections/CountriesSection'
import ConsultingSection from './sections/ConsultingSection'
import DisciplinesSection from './sections/DisciplinesSection'
import BookConsultingSection from './sections/BookConsultingSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import ReviewSection from './sections/ReviewSection'
import SubjectSection from './sections/SubjectSection'
import {Helmet} from "react-helmet";
import ScholarshipCountries from './sections/ScholarshipCountries'
import UniRankSection from './sections/UniRankSection'
import { connect } from 'react-redux'
export class MainPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    // console.log(window.innerWidth)
    return (
      <div className='mt-[80px] bg-[#F6F8FB]'>
        <Helmet>
          <title>{this.props.language?.main_page_seo_title}</title>
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this.props.language?.main_page_seo_title} />
          <meta property="og:description" content={this.props.language?.main_page_seo_desc}/>
          <meta name="description" content={this.props.language?.main_page_seo_desc}/>
          <meta name="keywords" content={this.props.language?.main_page_seo_keyword}/>
          <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`}/>
          <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
          <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="600" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={this.props.language?.main_page_seo_title} />
          <meta name="twitter:description" content={this.props.language?.main_page_seo_desc}/>
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="7 minutes" />
        </Helmet>
        <MainHeaderSection />
        <CountriesSection viewBtn={true} pt=" pt-[1px]" page={window.innerWidth<=500?"0.15": '0.25'} />
        <SubjectSection viewBtn={true} page={ window.innerWidth<=500?"0.15": '0.2'} />
        <StudentsSection viewBtn={true} page={window.innerWidth<=500?"0.15": '0.2'} />
        <ScholarshipCountries  viewBtn={true} page={window.innerWidth<=500?"0.15": '0.25'} />
        <UniRankSection />
        
       
        {/* <ConsultingSection /> */}
        {/* <DisciplinesSection viewBtn={true} page={'0.2'} /> */}
       
        {/* <ReviewSection /> */}
        <BookConsultingSection />
        
        {/* <NewsLetterSection /> */}
      
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language
})
export default connect(mapStateToProps)(MainPage)
