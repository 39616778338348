import React, { Component } from 'react'
import CountriesSection from './sections/CountriesSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

export class CountriesPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='bg-[#fff] mt-[-40px]'>
          <Helmet>
            <title>{this.props.language?.countries_page_seo_title}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={this.props.language?.countries_page_seo_title} />
            <meta property="og:description" content={this.props.language?.countries_page_seo_desc} />
            <meta name="description" content={this.props.language?.countries_page_seo_desc}/>
            <meta name="keywords" content={this.props.language?.countries_page_seo_keyword}/>
            <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
            <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={this.props.language?.countries_page_seo_title} />
            <meta name="twitter:description" content={this.props.language?.countries_page_seo_desc} />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>
            <CountriesSection loadMoreBtn={false} page={'5'} />
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language
})
export default connect(mapStateToProps)(CountriesPage)
