import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import Footer from './sections/Footer'
import ContactInput from '../../components/public_components/ContactInput'
import SmileSvg from '../../components/svg/SmileSvg'
import { changeStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import InpEmailSvg from '../../components/svg/InpEmailSvg'
import InpTelSvg from '../../components/svg/InpTelSvg'
import ContactDescription from '../../components/public_components/ContactDescription'
import InpDescSvg from '../../components/svg/InpDescSvg'
import InpSendSvg from '../../components/svg/InpSendSvg'
import BtnRightSvg from '../../components/public_components/svg/BtnRightSvg'
import { Helmet } from 'react-helmet'

export class ContactPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    const {contactInfo} = this.props
    return (
      <div className='mt-[40px] bg-[#fff]'>
             <Helmet>
              <title>{this.props.language?.contact_page_seo_title}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={this.props.language?.contact_page_seo_title} />
              <meta property="og:description" content={this.props.language?.contact_page_seo_desc} />
              <meta name="description" content={this.props.language?.contact_page_seo_desc}/>
              <meta name="keywords" content={this.props.language?.contact_page_seo_keyword}/>
              <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <meta property="og:image:width" content="900" />
              <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={this.props.language?.contact_page_seo_title} />
              <meta name="twitter:description" content={this.props.language?.contact_page_seo_desc} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
          <section className='bg-white pt-[100px]'>
            <div className=''>
                <SectionHeader text1={this.props.language?.section_title9} text2="" />
            </div>
          
            <div className='max-w-[1200px] ml-auto mr-auto mt-[70px]  max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] relative'>
              <div className='bg-white   rounded-[10px] shadow bottom-0 right-0 p-[60px] max-[600px]:relative max-[600px]:bottom-[unset] max-[600px]:mt-[20px] max-[600px]:w-full max-[400px]:p-[20px]'>
                <h2 className='text-[28px] text-[#313e3b] font-semibold'>CELT Knightsbridge</h2>
                <p className='text-[#828c8a] text-[17px] font-regular mt-[10px]'>151 8 November Ave, Baku 1000</p>
                <ul className='mt-[10px] flex flex-col gap-[10px]'>
                  <li className='text-[#828c8a]'><span className='font-semibold  text-[#313e3b]'>T:</span> <a href='tel:+994508585114'>+994 50 858 51 14</a></li>
                  <li className='text-[#313e3b] font-semibold'><span className='font-semibold  text-[#313e3b]'>E:</span> <a href='mailto:hello@studycoach.vip'>hello@studycoach.vip</a></li>
                </ul>
                <a target='_blank' href='https://maps.app.goo.gl/2eivA3pFgdSHiHmn7' className='bg-blue inline-flex items-center justify-center gap-[10px] rounded-full p-[10px_20px] text-white text-[14px] mt-[10px]'>
                  Google map
                  <BtnRightSvg />
                </a>
              </div>
              <iframe className='rounded-[10px] relative mt-[20px] max-[700px]:h-auto' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.376589711479!2d49.89197!3d40.3790646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d96c6119853%3A0x1daf056ac15ce9a7!2sCELT%20Knightsbridge!5e0!3m2!1sen!2saz!4v1719553103220!5m2!1sen!2saz" width="100%" height="800px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              
            </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  contactInfo: state.Data.contactInfo,
  language: state.Data.language,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
