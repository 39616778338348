import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { getCountryByScholarhip } from '../../../actions/PublicActions';
import { connect } from 'react-redux';
import Card2 from '../../../components/public_components/cards/Card2';
import ViewAllBtn from '../../../components/public_components/ViewAllBtn';
import LoadMoreBtn from '../../../components/LoadMoreBtn';

export class ScholarshipCountries extends Component {

    componentDidMount(){
        this.props.getCountryByScholarhip(this.props.page);
    }

    loadMore=(page)=>{
        this.props.getCountryByScholarhip(page);
    }
  render() {
    const {countryBySch, viewBtn=false, loadMoreBtn=false} = this.props;
    // console.log(countryBySch)
    return (
        <section>
            <div className='mt-[60px]'>
                <SectionHeader text1={this.props.language?.section_title1} text2="" />
            </div>
            <div className='max-w-[1200px] ml-auto mr-auto mt-[70px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='grid-full-220'>
                {
                        [...countryBySch].sort((a, b) => b.university_count - a.university_count)?.map((data, i)=>{
                            return(
                                <Card2
                                    key={i}
                                    data={data}
                                    path={"scholarhip-country"}
                                />
                            )
                        })
                    }
                </div>
                {
                    viewBtn?
                    <ViewAllBtn to={`/${this.props.langPrefix}scholarship-countries`} />:null
                }
                {
                    loadMoreBtn && countryBySch?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={countryBySch?.length} />:null
                }
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    countryBySch: state.Data.countryBySch,
    language: state.Data.language,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {getCountryByScholarhip}
export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipCountries)
