import React, { Component } from 'react'
import Footer from './sections/Footer'
import Top300UniversitiesRankingSections from './sections/Top300UniversitiesRankingSections'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

export class Top300Universities extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='bg-[#F5F5F5] mt-[-40px]'>
           <Helmet>
                <title>{this.props.language?.top_300_page_seo_title}</title>
                <meta property="og:locale" content="az_AZ" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={this.props.language?.top_300_page_seo_title} />
                <meta property="og:description" content={this.props.language?.top_300_page_seo_desc} />
                <meta name="description" content={this.props.language?.top_300_page_seo_desc}/>
                <meta name="keywords" content={this.props.language?.top_300_page_seo_keyword}/>
                <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
                <meta property="og:site_name" content="CELT Colleges" />
                <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
                <meta property="og:image:width" content="900" />
                <meta property="og:image:height" content="600" />
                <meta property="og:image:type" content="image/jpeg" />
                <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={this.props.language?.top_300_page_seo_title} />
                <meta name="twitter:description" content={this.props.language?.top_300_page_seo_desc} />
                <meta name="twitter:site" content="@celtcolleges" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            <Top300UniversitiesRankingSections />
            <Footer />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language
})
export default connect(mapStateToProps)(Top300Universities)


