import React, { Component } from 'react'
import CloseSvg from '../../../components/svg/CloseSvg'
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import BtnLeftSvg from '../../../components/svg/BtnLeftSvg';
import BtnRightSvg from '../../../components/svg/BtnRightSvg';
import TrackingLine from './TrackingLine';
import Country from './Country';
import Disciplines from './Disciplines';
import { bigAlert, mixinAlert } from '../../../actions/SweetAlerts';
import EduBack from './EduBack';
import Exams from './Exams';
import PersonalInfo from './PersonalInfo';
import EmailOrPhoneConfirmModal from './EmailOrPhoneConfirmModal';
import { insertNewDataParams } from '../../../actions/MainAction';
import { getCountryByEduBack, getDisciplinesByEduBack } from '../../../actions/PublicActions';

export class EducationPlanModal extends Component {

  closeBtn=()=>{
    this.props.resetStateValue({name:"educationPlanModalData"})
  }

  changePagePrev=(page)=>{
    let otherPage=page
    if(page==="discipline"){
      otherPage="country";
    }else if(page==="exam"){
      otherPage="edu_back";
    }else if(page==="edu_back"){
      otherPage="discipline";
    }else if(page==="info"){
      otherPage="exam";
    }
    this.props.changeStateValue({
      name:'educationPlanModalData.page',
      value: otherPage
    })
  }

  changePageNext=(page)=>{
    let otherPage=page;
    if(page==="country"){
      if(this.props.educationPlanModalData?.countries?.length !=0){
        otherPage="discipline";
      }else{
        mixinAlert("warning", this.props.language?.select_atleas_country)
      }
    }else if(page==="discipline"){
      if(this.props.educationPlanModalData?.disciplines?.length !=0){
        otherPage="edu_back";
      }else{
        mixinAlert("warning",this.props.language?.select_atleas_discipline)
      }
    }else if(page==="edu_back"){
      if(  
      this.props.educationPlanModalData.educationBackground?.finish_year !=="" &&
      (this.props.educationPlanModalData.educationBackground?.grade !=="" ||
      this.props.educationPlanModalData.educationBackground?.a_level !=="" ||
      this.props.educationPlanModalData.educationBackground?.ossd !=="" ||
      this.props.educationPlanModalData.educationBackground?.ib !=="" ||
      this.props.educationPlanModalData.educationBackground?.foundation !=="" ||
      this.props.educationPlanModalData.educationBackground?.ap !=="")){
        // console.log(this.props.applyDetails.educationBackground)
        // this.props.getCountryByEduBack(this.props.educationPlanModalData.educationBackground)
        // this.props.getDisciplinesByEduBack(this.props.educationPlanModalData.educationBackground)
        otherPage="exam";
        
      }else{
        mixinAlert("warning", this.props.language?.please_fill_all_inp)
      }
    }else if(page==="exam"){
      otherPage="info";
    }else if(page == "info"){
      if(
        this.props.educationPlanModalData.information?.first_name.trim()  != "" && 
        this.props.educationPlanModalData.information?.last_name.trim()  != "" && 
        this.props.educationPlanModalData.information?.email.trim()  != "" && 
        this.props.educationPlanModalData.information?.phone_number.trim()  != ""){
          if(!this.props.phoneValidFormat){
            mixinAlert("warning", this.props.language?.incorrect_phone);
            return;
          }
          if(!this.props.educationPlanModalData.information?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            mixinAlert("warning", this.props.language?.incorrect_email)
            return;
          }
          this.props.insertNewDataParams("check-email-phone", {email: this.props.educationPlanModalData.information?.email, phone_number:this.props.educationPlanModalData.information?.phone_number})
          .then(resp=>{
            if(resp.msg == "success"){
              this.props.changeStateValue({name:"confirmEmailWhatsaapModal", value:true})
            }else{
              this.props.changeStateValue({
                name:"incorrectModalData",
                value: {
                  position: true,
                  message: resp?.data
                }
              })
              // mixinAlert("warning", resp?.data);
            }
          })
          
        }else{
          mixinAlert("warning", this.props.language?.please_fill_all_inp)
        }
    }
    
    this.props.changeStateValue({
      name:'educationPlanModalData.page',
      value: otherPage
    })
  }
  getPage(page){
    if(page == "country"){
      return 1;
    }else if(page == "discipline"){
      return 2;
    }else if(page == "edu_back"){
      return 3;
    }else if(page == "exam"){
      return 4;
    }else{
      return 5
    }
  }

  getTitle(page){
    if(page=="country"){
      return this.props.language?.apply_title_1;
    }else if(page == "discipline"){
      return this.props.language?.apply_title_2;
    }else if(page == "edu_back"){
      return this.props.language?.apply_title_3;
    }else if(page == "info"){
      return this.props.language?.apply_title_4;
    }else if(page == "exam"){
      return this.props.language?.apply_title_5;
    }
  }

  render() {
    const {educationPlanModalData, confirmEmailWhatsaapModal} = this.props;
    // console.log(this.getPage(educationPlanModalData?.page))
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white  rounded-[10px] w-[597px] max-[640px]:ml-[20px] max-[640px]:mr-[20px]'>
          <div className='flex justify-center relative p-[18px_0] border-b'>
            <h2 className='text-[#032552] font-semibold text-[24px]   max-[640px]:text-[14px] text-center'>{this.getTitle(educationPlanModalData?.page)}</h2>
            <div className='absolute right-[-25px] top-[-25px] mt-0 bg-white w-[38px] h-[38px] flex justify-center items-center rounded-full max-[640px]:w-[30px] max-[640px]:h-[30px] max-[640px]:right-[-20px] max-[640px]:top-[-20px]'>
             <button onClick={this.closeBtn.bind(this)} className='w-[30.28px] h-[30.28px] rounded-full border border-[#1E398F] inline-flex justify-center items-center max-[640px]:w-[24px] max-[640px]:h-[24px]'>
              <CloseSvg color="#1E398F" />
             </button>
            </div>
          </div>

          <div className='p-[15px_11px]'>
            <TrackingLine page={this.getPage(educationPlanModalData?.page)} />
            {
              educationPlanModalData?.page == "country"?
              <Country />:
              educationPlanModalData?.page == "discipline"?
              <Disciplines />:
              educationPlanModalData?.page == "edu_back"?
              <EduBack />:
              educationPlanModalData?.page == "exam"?
              <Exams />:
              educationPlanModalData?.page == "info"?
              <PersonalInfo />:null
            }

          <div className='mt-[45px] flex gap-[25px]'>
              {
                educationPlanModalData?.page !== "country"?
                <button onClick={this.changePagePrev.bind(this, educationPlanModalData?.page)} className='w-full h-[48px] bg-[#EE7100] rounded-[8px] inline-flex justify-center items-center text-white text-[20px] font-regular relative max-[400px]:text-[16px]'>
                  <span className='absolute left-[16px]'>
                    <BtnLeftSvg />
                  </span>
                  <span>{this.props.language?.prev}</span>
                </button>:null
              }

              <button onClick={this.changePageNext.bind(this, educationPlanModalData?.page)} className={`w-full h-[48px] ${ educationPlanModalData?.page === "country"?"bg-[#EE7100]":"bg-[#1E398F]"}  rounded-[8px] inline-flex justify-center items-center text-white text-[20px] font-regular relative max-[400px]:text-[16px]`}>
                <span>{this.props.language?.next}</span>
                <span className='absolute right-[16px]'>
                  <BtnRightSvg />
                </span>
              </button>
          </div>
          </div>
        </div>
        {confirmEmailWhatsaapModal? <EmailOrPhoneConfirmModal />:null}
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  educationPlanModalData: state.Data.educationPlanModalData,
  phoneValidFormat: state.Data.phoneValidFormat,
  confirmEmailWhatsaapModal: state.Data.confirmEmailWhatsaapModal,
  language: state.Data.language,
});
const mapDispathToProps = {changeStateValue, resetStateValue, insertNewDataParams, getDisciplinesByEduBack, getCountryByEduBack}
export default connect(mapStateToProps, mapDispathToProps)(EducationPlanModal)
