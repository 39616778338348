import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getSTAF } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { withHooks } from '../../actions/withHooks'
import defUserImg from "../../images/def_user.png"
import ChangeTNModal from '../../components/modals/ChangeTNModal'
import moment from 'moment'
import StudenMoreInfoModal from '../../components/modals/StudenMoreInfoModal'
export class Students extends Component {
  state={
    page:1,
    search:'',
  }
  openModal=(data)=>{
    this.props.navigate(`/students/${data==""?"create":data?.id}`)
   
  }

  componentDidMount(){
    this.props.getSTAF(1, '', 'student')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getSTAF(this.state.page, search, 'student')
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSTAF(page, this.state.search, 'student')
  }

  afterCallFunction=()=>{
    this.props.getSTAF(this.state.page, this.state.search, 'student')
  }

  onChangeBtn=(data)=>{
    this.props.changeStateValue({
      name:"changeTNModalData",
      value:{
        position: true,
        user_id: data?.id,
        staff_id: data?.related_user?.staff_id
      }
    })
  }
  onClickMore=(data)=>{
    // console.log(data)
    let obj = {
      position: true,
      email: data?.email,
      phone_number: data?.phone_number,
      register_type: data?.edu_back?.finish_year?"Education plan":"Apply",
      registerDate: moment(data?.created_at)?.format("DD/MM/YYYY"),
      verify_type: data?.verify_type
    }
    this.props.changeStateValue({
      name:"studentMoreInfoModalData",
      value: obj
    })

  }
  render() {
    const {staff, user, changeTNModalData, studentMoreInfoModalData} = this.props;
    // console.log(studentMoreInfoModalData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Tələbələr" />
            {
              user?.role === "teacher"?
              <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Tələbə əlavə et", value:0}]} padding={true} selected={0} />
            </div>:null
            }
            
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                staff?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url? data?.image_full_url:defUserImg}
                      name={data?.full_name}
                      onClickEdit={this.openModal.bind(this, data)}
                      // deletePath={`users/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/student-info/${data?.id}`}
                      username={"İstifadəçi adı: "+data?.username}
                      relatedUser={data?.related_user?.staff?.full_name}
                      relatedUserManager={data?.related_user?.staff?.related_user?.staff?.full_name}
                      changeStaffBtn={true}
                      onChangeBtn={this.onChangeBtn.bind(this, data)}
                      moreInfo={user?.role == "xtm" || user?.role == "manager"?true:false}
                      onClickMore={this.onClickMore.bind(this, data)}
                    />
                  )
                })
              }
            </div>
            {
                staff?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staff?.length} />:null
            }
            {changeTNModalData?.position ? <ChangeTNModal afterCallFunction={this.afterCallFunction.bind(this)} /> :null}
            
            {studentMoreInfoModalData?.position? <StudenMoreInfoModal />:null }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  staff: state.Data.staff,
  user: state.Data.user,
  changeTNModalData: state.Data.changeTNModalData,
  studentMoreInfoModalData: state.Data.studentMoreInfoModalData,
});
const mapDispatchToProps = {changeStateValue, getSTAF}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Students))




