import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getPublicCountries, getPublicEduDegree, getPublicHighSchoolExams } from '../../../actions/PublicActions';
import DropSearch from '../../../components/modals/components/DropSearch';
import Input from '../../../components/modals/components/Input';
import { getDropValue, getTranslateLanguage } from '../../../actions/getOtherFunctions';
import WorldSvg from '../../../components/svg/WorldSvg';
import EduSvg from '../../../components/svg/EduSvg';
import DiplomaSvg from '../../../components/svg/DiplomaSvg';
import GradeSvg from '../../../components/svg/GradeSvg';
import InpSvg from '../../../components/svg/InpSvg';
import DropGraduateSvg from '../../../components/svg/DropGraduateSvg';

export class EduBack extends Component {
    state={
        sub_degrees:[]
    }
    componentDidMount(){
        this.props.getPublicCountries(2);
        this.props.getPublicHighSchoolExams();
        this.props.getPublicEduDegree();
    }
    onSelectDegree=(countryId, degrees, degreeId)=>{
        let subDegrees = degrees?.find(x=>x?.id == degreeId)?.sub_degrees?.filter(y=>y?.country_id == countryId);
        this.setState({sub_degrees:subDegrees})
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.sub_edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.foundation', value:'' });
    }
    onSelectCountry=()=>{
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.sub_edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.foundation', value:'' });

    }
    onSelectSubDegree=()=>{
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'educationPlanModalData.educationBackground.foundation', value:'' });

    }
    changePageBack=()=>{
        this.props.onChangePage("discipline")
    }
    changePage=()=>{
        this.props.onChangePage("info")
    }
    getPlaceholder(name){
        if(name == "IB"){
            return "Yalnız 24 və 45 aralığında qiymət daxil edin.";
        }else if (name == "OSSD"){
            return "Yalnız 50 və 100 aralığında qiymət daxil edin."
        }else if (name == "Foundation"){
            return "Yalnız 40 və 100 aralığında qiymət daxil edin."
        }else{
            return ""
        }
    }
  render() {
    const {educationPlanModalData, publicEducationDegree, publicHighSchoolExams, publicCountries} = this.props;
   
    const {sub_degrees} = this.state;
    let countryOption = [];
    for(const proeprty of publicCountries){
      if(proeprty?.id===1 || proeprty?.id === 5){
        countryOption.push({
          label: getTranslateLanguage(this.props.lang_id, "name", proeprty),
          value: proeprty?.id
        })
      }
    }
    let subDegrees = [...getDropValue(sub_degrees, "name","id")];
    for(const exam of publicHighSchoolExams){
      subDegrees.push({
        label: exam?.name,
        value: exam?.name?.replaceAll(" ", "_")?.toLowerCase()
      })
    }
    let degreeOpt = [];
    for(const data of publicEducationDegree?.filter(x=>x.id ==1|| x.id==3 || x.id == 4 || x.id == 5)){
        degreeOpt.push({
            label: getTranslateLanguage(this.props.lang_id, "name", data),
            value: data?.id
        })
    }
    let graduateYearOption = [
        {
            label:this.props.language?.graduated_already,
            value: "graduated"
        },
        {
            label:"2025",
            value: "2025"
        },
        {
            label:"2026",
            value: "2026"
        },
        {
            label:"2027",
            value: "2027"
        },
        {
            label:"2028",
            value: "2028"
        },
    ]
    return (
      <div className='mt-[40px]'>
        
         <div className='grid grid-cols-2 gap-[20px] max-[640px]:grid-cols-1'>
            <DropSearch 
                svg={<WorldSvg />} bigDrop 
                required name="educationPlanModalData.educationBackground.country_id"  
                selectData={this.onSelectCountry.bind(this)}
                value={educationPlanModalData.educationBackground.country_id} 
                placeholder={this.props.language?.study_country} 
                options={countryOption} 
                title={this.props.language?.study_country} />
            {
                educationPlanModalData.educationBackground?.country_id !=""?
                <DropSearch svg={<EduSvg />} bigDrop required 
                    name="educationPlanModalData.educationBackground.edu_degree_id"  
                    selectData={this.onSelectDegree.bind(this,educationPlanModalData.educationBackground.country_id, publicEducationDegree, )} 
                    value={educationPlanModalData.educationBackground.edu_degree_id} 
                    placeholder={this.props.language?.last_edu_diploma} 
                    options={degreeOpt} 
                    reqText={this.props.language?.select_edu_diploma}
                    title={this.props.language?.last_edu_diploma} />:null
            }
            {
                educationPlanModalData.educationBackground?.edu_degree_id ===1 || educationPlanModalData.educationBackground?.edu_degree_id === 2?
                <DropSearch svg={<DiplomaSvg />} bigDrop required 
                    name="educationPlanModalData.educationBackground.sub_edu_degree_id"  
                    selectData={this.onSelectSubDegree.bind(this)} 
                    value={educationPlanModalData.educationBackground.sub_edu_degree_id}
                    reqText={this.props.language?.select_sch_diploma}
                    placeholder={this.props.language?.school_diploma} options={subDegrees} title={this.props.language?.school_diploma} />:null
            }
            {
                publicHighSchoolExams?.map((data, j)=>{
                if(data?.name?.replaceAll(" ", "_")?.toLowerCase() ===  educationPlanModalData.educationBackground?.sub_edu_degree_id){
                    if(data?.grades?.length !==0){
                    return(
                        <DropSearch svg={<GradeSvg />} bigDrop key={j} 
                            required name={`educationPlanModalData.educationBackground.${educationPlanModalData.educationBackground?.sub_edu_degree_id}`} 
                            value={educationPlanModalData.educationBackground[educationPlanModalData.educationBackground?.sub_edu_degree_id]} 
                            placeholder={data?.name+" "+this.props.language?.grade_select} options={getDropValue(data?.grades, "grade", "grade")} 
                            reqText={data?.name+" "+this.props.language?.grade_select}
                            title={data?.name} />
                    )
                    }else{
                    return(
                        <Input bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]" key={j} 
                            required
                            minValue={data?.min_value} 
                            maxValue={data?.max_value} 
                            step={1}
                            name={`educationPlanModalData.educationBackground.${educationPlanModalData.educationBackground?.sub_edu_degree_id}`}  
                            value={educationPlanModalData.educationBackground[educationPlanModalData.educationBackground?.sub_edu_degree_id]} 
                            title={`${data?.name} (${data?.min_value} - ${data?.max_value})`} 
                            reqText={this.getPlaceholder(data?.name)}
                            placeholder={data?.name+" "+this.props.language?.grade_predicted}
                            />
                    )
                    }
                    
                }
                })
            }
             {
                educationPlanModalData.educationBackground?.sub_edu_degree_id ===1?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={3} 
                maxValue={5} 
                step={1}
                name={`educationPlanModalData.educationBackground.grade`}  
                value={educationPlanModalData.educationBackground?.grade} 
                title={`Attestat ortalama qiyməti`}  
                placeholder={`${this.props.language?.example}: 3, 3.5, 4, 4.5, 5`}
                reqText={this.props.language?.required_txt}/>:null
            }
            {
                educationPlanModalData.educationBackground?.sub_edu_degree_id ===2?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={1} 
                maxValue={100} 
                step={1}
                reqText={this.props.language?.required_txt}
                name={`educationPlanModalData.educationBackground.grade`}  
                value={educationPlanModalData.educationBackground?.grade} 
                title={`Lise diploması (1 - 100)`}  />:null
            }
            {
                educationPlanModalData.educationBackground?.edu_degree_id !=="" &&educationPlanModalData.educationBackground?.edu_degree_id !==1 && educationPlanModalData.educationBackground?.edu_degree_id !== 2?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={1} 
                maxValue={100} 
                step={1}
                name={`educationPlanModalData.educationBackground.grade`}  
                value={educationPlanModalData.educationBackground?.grade} 
                reqText={this.props.language?.required_txt}
                title={`${this.props.language?.grade} (1 - 100)`}  />:null
            }

            <DropSearch 
                svg={<DropGraduateSvg />} bigDrop 
                required name="educationPlanModalData.educationBackground.finish_year"  
                value={educationPlanModalData.educationBackground.finish_year} 
                placeholder={this.props.language?.graduate_year}
                options={graduateYearOption} 
                reqText={this.props.language?.select_grad_year}
                title={this.props.language?.graduate_year}/>
         </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educationPlanModalData: state.Data.educationPlanModalData,
    publicEducationDegree: state.Data.publicEducationDegree,
    publicHighSchoolExams: state.Data.publicHighSchoolExams,
    publicCountries: state.Data.publicCountries,
    lang_id: state.Data.lang_id,
    language: state.Data.language,
});
const mapDispatchToProps = {changeStateValue, getPublicCountries, getPublicHighSchoolExams, getPublicEduDegree}
export default connect(mapStateToProps, mapDispatchToProps)(EduBack)



