import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import Card3 from '../../../components/cards/Card3'
import Top20Img from "../../../images/top20Uni.jpeg"
import Top3000Img from "../../../images/top300Uni.jpeg"
import TopSubject from "../../../images/subjectUni.jpeg"
import { connect } from 'react-redux'
export class UniRankSection extends Component {
  render() {
    return (
      <div className=' '>
         <section className='pt-[1px]'>
                <div className='mt-[30px]'>
                    <SectionHeader text1={this.props.language?.section_title2} text2="" />
                </div>
                
            </section>
            <section className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] pb-[50px]'>
                <div className='grid  grid-full-320 gap-[70px] mt-[20px]'>
                    <Card3
                        title={this.props.language?.uni_card_title1}
                        btnText={this.props.language?.accept_change_check} 
                        img={Top20Img}
                        url={`/${this.props.langPrefix}top-20-universities`} />
                    <Card3 
                        title={this.props.language?.uni_card_title2}
                        btnText={this.props.language?.accept_change_check} 
                        img={Top3000Img}
                        url={`/${this.props.langPrefix}top-300-universities`} />
                    <Card3 
                        title={this.props.language?.uni_card_title3}
                        btnText={this.props.language?.accept_change_check} 
                        img={TopSubject}
                        url={`/${this.props.langPrefix}subjects`} />
                </div>
                
            </section>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
})
export default connect(mapStateToProps)(UniRankSection)
