import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("celtGoMainToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}
export const getErrors=(error)=>async dispatch => {
    if(error.response?.status !== 404 && error.response?.status !== 422){
       dispatch(changeStateValue({name:'errorPageData', value:{position: true, code: error.response?.status}})) 
    }else if(error.response?.status === 404){
        dispatch(changeStateValue({name:"notFoundModal", value:true}))
    }
}

export const loginAction = (data)=> async dispatch => {
    return await axios.post(`${MAIN_API}/login`, data)
    .then(resp=>{
        // console.log(resp.data)
        localStorage.setItem("celtGoMainToken", resp?.data?.token);
        dispatch(changeStateValue({name:'user', value:resp?.data?.user}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const checkLoginUser = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/user`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"user", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}


export const getCountries = (page=1, search="", type="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/country`,{
        params:{page, search, type, uni_count:"uni_count"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"countries", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getDisciplines = (page=1, search="", type="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/discipline`,{
        params:{page, search, type, program_count:'program_count'},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"disciplines", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getStates = (page=1, search="", type="", country_id="", countries="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/state`,{
        params:{page, search, type, country_id, countries},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"states", value:resp.data}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getCities = (page=1, search="", type="", country_id="", state_id="", countries="", states="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    // console.log(states)
    return await axios.get(`${MAIN_API}/city`,{
        params:{page, search, type, country_id, state_id, countries, states},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"cities", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getUniversities = (page=1, search="", type="", country_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/university`,{
        params:{page, search, type, country_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"universities", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getUniType = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/university-type`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"uniTypes", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getEduDegree = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/education-degree`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"educationDegree", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getLanguages = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/language`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"languages", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getPrograms = (page=1, search="", university_id="", degree_id="", discipline_id="", active_status="", sch_id="", content_program="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/programs`,{
        params:{page, search, university_id, degree_id, discipline_id, active_status, sch_id, content_program},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"programs", value:resp.data}));
        return resp.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return [];
    })
}
export const getExams = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/exams`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"exams", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getHighSchoolExams = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/high-school-exams`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"highSchoolExams", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}
export const getExpencesCategories = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/expences-categories`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"expencesCategories", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}
export const getCurrencies= () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/currency`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"currencies", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}
export const getStudentExpences= (student_id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-applied-expences?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentExpencesData", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}



export const getSTAF = (page=1, search="", type="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users`,{
        params:{page, search, type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"staff", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}
export const getTeacherSudents = (page=1, search="",teacher_id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/teacher-students`,{
        params:{page, search, teacher_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"teacherStudents", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}
export const getAllTeachers = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users`,{
        params:{type:"teacher", page_type:"all"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"allTeachers", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return "error";
    })
}
export const getEducationBackground = (id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-edu-background/${id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"edubackground", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "";
    })
}
export const getStudyDestination = (id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-study-destination/${id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studyDestination", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return {
            countries:[],
            states:[],
            cities:[],
            disciplines:[]
          };
    })
}
export const getStudentExams = (id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-exams/${id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentExams", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return "";
    })
}

export const getStudentPrograms = (page=1,student_id, keyword="") => async dispatch =>  {
   
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-programs?student_id=${student_id}&page=${page}&keyword=${keyword}&match_all=match`,{
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp.data)
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getStudentAppliedPrograms = (student_id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/applied-programs?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getMesages = (params) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/messages`,{
        params:params,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getApplyRequirements = (student_id="", program_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/apply-requirements?student_id=${student_id}&program_id=${program_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"applyRequirements", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getStudentRequirements = (student_id="", program_id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-requirements?student_id=${student_id}&program_id=${program_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentRequirements", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getStudentWishLists = (student_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-wish-list?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentWishListData", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getCostumerReview = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-costumer-review`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"costumerReview", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getBlogs = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-blog`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"blogs", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}
export const getBookingInfo = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/booking-info`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"bookingInfo", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}

export const getPersonalInformation = (student_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-personal-information?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentPersonalInformation", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}

export const getNotFeeUniversities = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/notfee-universities?page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"notFeeUni", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}



export const getStudentProgramAndUni = (student_id = "") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-program-universities?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"selectedUniAndPrograms", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return [];
    })
}





export const getSingleData = (url) => async dispatch =>  {
    // console.log(url)
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/${url}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getCheckStudentApply = (id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/check-student-apply?student_id=${id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"checkStudentApply", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getBookings = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/get-booking-info`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminBookings", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}




export const getCGOPrograms = (country, page) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`https://goapi.celt.vip/api/v1/get-prog-filter?country=${country}&page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getWorldCountries = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`https://restcountries.com/v3.1/all`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"worldCountries", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getCityRankings = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/city-rankings`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"cityRankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getUniversityRankings = (id) => async dispatch =>  {
    // console.log(id)
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/university-rankings?country_id=${id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"universityRankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getEmployeeRankings = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/employ-rankings`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"employRankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getTop300Rankings = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/top300-rankings?page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"top300Rankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getTop20Rankings = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/top20-rankings`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"top20Rankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getTeacherComplete = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/teacher-profile-complete`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"teacherProfileComplete", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getTeacherApplicationStatus = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/teacher-application-status`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"teacherApplicationStatus", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}




export const getSubjectRankings = (page=1,id) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/subject-rankings?subject_id=${id}&page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"subjectRankings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getSubjects = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-subjects?page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"subjectsData", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}



export const getApplyRanking = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/ranking-apply`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"applyRankingsData", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getStudentComplete = (student_id="",) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/profile-complete?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"profileComplete", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getApplicationTracking = (student_id="", program_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/application-trackings?student_id=${student_id}&program_id=${program_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"applicationTrackings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getAccomadationTracking = (student_id="", program_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/accomadation-trackings?student_id=${student_id}&program_id=${program_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"accomadationTrackings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getVisaTracking = (student_id="",  program_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/visa-trackings?student_id=${student_id}&program_id=${program_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"visaTrackings", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}
export const getCountrySch = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/country-scholarship`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"countryScholarship", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}



export const getStudentMainExpences = (student_id="",  program_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-expencess?student_id=${student_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentMainExpences", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getMessagesActiveChats = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/messages?message_type=chat_users`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"messagesActiveUsers", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getMessagesContacts = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/messages?message_type=contacts`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"messagesContacts", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getAdvertisings = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/advertising`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"advertisingData", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getStudentDashData = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-dash-data`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentDashData", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}

export const getCeltAzApplies = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/admin-celtaz-applies`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"celtAzApplies", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}



export const getDashApplies = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/dash-applies-stats`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"dashAppliesData", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getEmtpyUniversities = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/empty-universities?page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"emptyUniversities", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}


export const getGeneralSch = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/general-scholarship`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"generalScholarship", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "";
    })
}





export const insertNewData = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        // console.log(resp?.data)
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        return 'error'
    })
}
export const insertNewDataParams = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        // console.log(resp?.data)
        return {msg: "success", data:resp?.data}
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        return {msg:'error', data: err.response?.data?.msg}
    })
}

export const deleteData = (url) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.delete(`${MAIN_API}/${url}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return 'error'
    })
}