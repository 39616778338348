import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import Card1 from '../../../components/public_components/cards/Card1'
import { connect } from 'react-redux';
import { getAccStudent } from '../../../actions/PublicActions';
import ViewAllBtn from '../../../components/public_components/ViewAllBtn';
import LoadMoreBtn from '../../../components/public_components/LoadMoreBtn';
export class StudentsSection extends Component {
    componentDidMount(){
        this.props.getAccStudent(this.props.page)
    }
    loadMore=(page)=>{
        this.props.getAccStudent(page)
    }
  render() {
    const {acceptedStudents, viewBtn=false, loadMoreBtn=false} = this.props;
    return (
        <section>
            <div className='mt-[60px]'>
                <SectionHeader text1={this.props.language?.section_title3} text2="" />
            </div>
            <div className='bg-white mt-[230px] pt-[1px]'>
                <div className='max-w-[1200px] ml-auto mr-auto mt-[-170px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                    <div className='grid-full-280'>
                        {
                            acceptedStudents?.map((data, i)=>{
                                return(
                                    <Card1 
                                        key={i}
                                        data={data}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                {
                    viewBtn?
                    <ViewAllBtn to={`/${this.props.langPrefix}accepted-students`} />:null
                }
                {
                    loadMoreBtn && acceptedStudents?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={acceptedStudents?.length} />:null
                }
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state)=>({
    acceptedStudents: state.Data.acceptedStudents,
    language: state.Data.language,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {getAccStudent}
export default connect(mapStateToProps, mapDispatchToProps)(StudentsSection)
