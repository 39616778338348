import React, { Component } from 'react'
import Headerbar from './Headerbar'

export class Header extends Component {
  render() {
    const {title} = this.props
    return (
        <header className='flex w-full justify-between mt-[30px] items-center  max-[950px]:justify-center'>
            <h1 className='txt-dark-blue font-bold text-[34px] max-[400px]:text-[24px] flex items-center gap-[9px]'>{title}</h1>
            {/* <Headerbar /> */}
        </header>
    )
  }
}

export default Header
