import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Header from '../../components/header/Header';
import { withHooks } from '../../actions/withHooks';
import CardEditSvg from '../../components/svg/CardEditSvg';
import PlusSvg from '../../components/svg/PlusSvg';
import UserSettingSvg from '../../components/svg/UserSettingSvg';
import { getSingleData, getEducationBackground, getStudyDestination, getStudentExams, getPersonalInformation, getCheckStudentApply, insertNewData } from '../../actions/MainAction';
import CourseEnrollSvg from '../../components/svg/CourseEnrollSvg';
import ContractSvg from '../../components/svg/ContractSvg';
import TeacherEnroll from '../../components/svg/TeacherEnroll';
import { Link } from 'react-router-dom';
import InfoButtton from '../../components/InfoButtton';
import UserInfoEditSvg from '../../components/svg/UserInfoEditSvg';
import PersInfoSvg from '../../components/svg/PersInfoSvg';
import PlusSvg2 from '../../components/svg/PlusSvg2';
import EduBackSvg from '../../components/svg/EduBackSvg';
import StudDestSvg from '../../components/svg/StudDestSvg';
import ExamsSvg from '../../components/svg/ExamsSvg';
import SvgContainer from '../../components/svg/SvgContainer';
import ExamCardSvg from '../../components/svg/ExamCardSvg';
import AddressCardSvg from '../../components/svg/AddressCardSvg';
import PassportCardSvg from '../../components/svg/PassportCardSvg';
import CVCardSvg from '../../components/svg/CVCardSvg';
import {confirmAlert, mixinAlert} from "../../actions/SweetAlerts"
export class StudentInfo extends Component {
  state={
    studentData:'',
    edubackground:'',
    studyDestination:{
      countries:[],
      states:[],
      cities:[],
      disciplines:[]
    },
    studentExams:''
  }

  getParams(){
    if(this.props.user !=""){
      this.props.getEducationBackground(this.props.user?.id)
 
      this.props.getStudyDestination(this.props.user?.id)
     
      this.props.getStudentExams(this.props.user?.id)

      this.props.getPersonalInformation(this.props.user?.id);
      this.props.getCheckStudentApply()
    }
  }
  componentDidMount(){
    this.getParams()
   
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.user !=this.props.user){
      this.getParams()
    }
  }
  afterCallFunction=()=>{
    this.rematchBtn(this.state.studentData?.id)
    confirmAlert("warning", "Profilinizi yenilədiniz.", "Yenidən axtarış edib proqramları görmək istəyirsiniz?", "Bəli", "Xeyir")
    .then(respAlert=>{
      if(respAlert == "success"){
        let formData = new FormData();
        formData.append("student_id", this.props?.user?.id);
        formData.append("match_all", "match");
        this.props.insertNewData("match-programs", formData)
        .then(resp=>{
          // console.log(resp)
          if(resp === "success"){
            this.props.navigate("/available-programs")
            mixinAlert("success", "İxtisaslarınız uğurla yeniləndi.")
          }
        })
      }
    })
    // if(this.props.params?.id !== "create"){
    //   this.props.getSingleData(`users/${this.props.params?.id}`)
    //   .then(resp=>{
    //     this.setState({studentData:resp})
    //   })
    // }
  }
  
  addEditStudentBtn=(data)=>{
    this.props.changeStateValue({
      name:'userModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        first_name: data?.first_name?data?.first_name:'',
        last_name: data?.last_name?data?.last_name:'',
        email: data?.email?data?.email:'',
        password: data?.password?data?.password:'',
        date_of_birth: data?.date_of_birth?data?.date_of_birth:'',
        phone_number: data?.phone_number?data?.phone_number:'',
        image: data?.image?data?.image:'',
        role: 'student',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        afterCallFunction:this.afterCallFunction,
        afterCallParams:[],
        requireds: ["first_name", "last_name", "email"],
        url:'users',
        insertType:"student",
        modalTitle:`${data?.id?'Məlumatı yenilə':'Tələbə əlavə et'}`
      }
    })
  }

  onClickAddEduBack=(data)=>{
    this.props.getEducationBackground(this.props.user?.id)
    .then(data=>{
      this.props.changeStateValue({
        name:'eduBackModal',
        value:{
          position: true,
          student_id: this.props.params?.id,
          country_id: data?.country_id?data?.country_id:'1',
          edu_degree_id: data?.edu_degree_id?data?.edu_degree_id:'',
          sub_edu_degree_id: data?.sub_edu_degree_id?data?.sub_edu_degree_id:'',
          a_level: data?.a_level?data?.a_level:'',
          ossd: data?.ossd?data?.ossd:'',
          foundation: data?.foundation?data?.foundation:'',
          ap: data?.ap?data?.ap:'',
          ib: data?.ib?data?.ib:'',
          grade: data?.grade?data?.grade:'',
          modalTitle:`Təhsil məlumatınız`,
          afterCallFunction: this.afterCallFunction,
          afterCallParams:[]
        }
      })
    })
    
  }
  getCountryData(array){
    let newArr = [];
    array.forEach(x=>newArr.push({label: x?.country?.name, value: x?.country_id}));
    return newArr;
  }
  getStateData(array){
    let newArr = [];
    array.forEach(x=>newArr.push({label: x?.state?.name, value: x?.state_id}));
    return newArr;
  }
  getCitiesData(array){
    let newArr = [];
    array.forEach(x=>newArr.push({label: x?.city?.name, value: x?.city_id}));
    return newArr;
  }
  getDisciplineData(array){
    let newArr = [];
    array.forEach(x=>newArr.push({label: x?.discipline?.name, value: x?.discipline_id}));
    return newArr;
  }
  onClickAddStudyDest=()=>{
    this.props.getStudyDestination(this.props.user?.id)
      .then(data=>{
        this.props.changeStateValue({
          name:'studyDestinationModal',
          value:{
            position: true,
            student_id: this.props.params?.id,
            countries: this.getCountryData(data?.countries),
            states: this.getStateData(data?.states),
            cities: this.getCitiesData(data?.cities),
            disciplines: this.getDisciplineData(data?.disciplines),
            modalTitle:`Oxumaq istədiyiniz ölkə və sahə`,
            afterCallFunction: this.afterCallFunction,
            afterCallParams:[]
          }
        })
      })
  }

  onClickAddStudentExam=()=>{
    this.props.getStudentExams(this.props.user?.id)
    .then(data=>{
      // console.log(data)
      this.props.changeStateValue({
        name:'studentExamsModal',
        value:{
          position: true,
          student_id: this.props.params?.id,
          ielts: data?.ielts?data?.ielts:'',
          toefl: data?.toefl?data?.toefl:'',
          gre: data?.gre?data?.gre:'',
          gmat: data?.gmat?data?.gmat:'',
          sat: data?.sat?data?.sat:'',
          dim: data?.dim?data?.dim:'',
          ielts_ukvi: data?.ielts_ukvi?data?.ielts_ukvi:'',
          ielts_file: data?.ielts_file?data?.ielts_file:'',
          toefl_file: data?.toefl_file?data?.toefl_file:'',
          gre_file: data?.gre_file?data?.gre_file:'',
          gmat_file: data?.gmat_file?data?.gmat_file:'',
          sat_file: data?.sat_file?data?.sat_file:'',
          dim_file: data?.dim_file?data?.dim_file:'',
          ielts_ukvi_file: data?.ielts_ukvi_file?data?.ielts_ukvi_file:'',
          ielts_full_url: data?.ielts_full_url?data?.ielts_full_url:'',
          toefl_full_url: data?.toefl_full_url?data?.toefl_full_url:'',
          gre_full_url: data?.gre_full_url?data?.gre_full_url:'',
          gmat_full_url: data?.gmat_full_url?data?.gmat_full_url:'',
          sat_full_url: data?.sat_full_url?data?.sat_full_url:'',
          dim_full_url: data?.dim_full_url?data?.dim_full_url:'',
          ielts_ukvi_full_url: data?.ielts_ukvi_full_url?data?.ielts_ukvi_full_url:'',
          modalTitle:`İmtahanlar`,
          afterCallFunction: this.afterCallFunction,
          afterCallParams:[]
        }
      })
    })
  }

  onClickStudentAddress=(data)=>{
    this.props.changeStateValue({
      name:'studentAddressData',
      value:{
        position: true,
        id:data?.id?data?.id:'',
        student_id: this.props.params?.id,
        country:data?.country?data?.country:'Azerbaijan',
        city:data?.city?data?.city:'',
        address:data?.address?data?.address:'',
        modalTitle:`${data?.id?'Edit':'Add'} Address`,
        afterCallFunction: ()=>{},
        afterCallParams:[]
      }
    })
  }
  onClickAddCV=(data)=>{
    this.props.changeStateValue({
      name:'studentCvModal',
      value:{
        position: true,
        id:data?.id?data?.id:'',
        student_id: this.props.params?.id,
        cv:data?.cv?data?.cv:'',
        cv_full_url:data?.cv_full_url?data?.cv_full_url:'',
        cv_type:data?.cv_type?data?.cv_type:'',
        modalTitle:`${data?.id?'Edit':'Add'} CV`,
        afterCallFunction:  ()=>{},
        afterCallParams:[]
      }
    })
  }

  onClickAddPassport=()=>{
    this.props.changeStateValue({
      name:'studentPassportModalData',
      value:{
        position: true,
        
      }
    })
  }

  rematchBtn(student_id){

    confirmAlert("warning","Profilinizi yenilədiniz.", "Yenidən axtarış edib proqramları görmək istəyirsiniz?", "Bəli", "Xeyir")
    .then(alertResp=>{
        if(alertResp === "success"){
          let formData = new FormData();
            formData.append("student_id", student_id);
            formData.append("match_all", "match");
            this.props.insertNewData("match-programs", formData)
            .then(resp=>{
              // console.log(resp)
              if(resp === "success"){
                this.props.navigate("/available-programs")
                mixinAlert("success", "İxtisaslarınız uğurla yeniləndi.")
              }
            })
        }
    })
    
  }
  render() {
    const {user,  edubackground, studyDestination, studentExams, studentPersonalInformation, checkStudentApply}= this.props;
    // console.log(checkStudentApply)
    return (
      <div className='w-full  pl-[27px] pr-[27px]'>
       <Header title={"Təhsil Profiliniz"} />

        <div className='grid grid-full-220 gap-[20px] mt-[40px] items-start'>
          <InfoButtton 
            onClick={this.addEditStudentBtn.bind(this, user)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<PersInfoSvg />}
            title="Şəxsi məlumat"
            btnText={this.props.params?.id !== "create"?"Tamamlandı":"Natamam"}
          />
       
          <InfoButtton 
            onClick={this.onClickAddEduBack.bind(this, edubackground)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<EduBackSvg />}
            title="Təhsil məlumatınız"
            btnText={edubackground !=""?  "Tamamlandı":"Natamam"}
          />

          <InfoButtton 
            onClick={this.onClickAddStudyDest.bind(this, studyDestination)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<StudDestSvg />}
            title="Ölkə və ixtisas"
            btnText={studyDestination?.countries?.length !==0 && studyDestination?.disciplines?.length !==0? "Tamamlandı":"Natamam"}
          />

          <InfoButtton 
            onClick={this.onClickAddStudentExam.bind(this, studyDestination)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<SvgContainer innerSvg={<ExamCardSvg />} />}
            title="İmtahanlar"
            btnText={studentExams !=""?   "Tamamlandı":"Natamam"}
            activateStatus={checkStudentApply || studentExams !=""}
          />  
          <InfoButtton 
            onClick={this.onClickStudentAddress.bind(this, studentPersonalInformation?.info)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<SvgContainer innerSvg={<AddressCardSvg />} color={"#CDF5F6"} />}
            title="Ünvan"
            btnText={studentPersonalInformation?.info ?   "Tamamlandı":"Natamam"}
            activateStatus={checkStudentApply || studentPersonalInformation?.info}
          />  
          <InfoButtton 
            onClick={this.onClickAddPassport.bind(this)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<SvgContainer innerSvg={<PassportCardSvg />} color={"#EFF9DA"} />}
            title="Pasport"
            btnText={studentPersonalInformation?.passport?.length !=0 ?   "Tamamlandı":"Natamam"}
            activateStatus={checkStudentApply || studentPersonalInformation?.passport?.length !=0}
          />  
          <InfoButtton 
            onClick={this.onClickAddCV.bind(this, studentPersonalInformation?.info)}
            headerIcon={<UserInfoEditSvg />}
            headerText={"Yenilə"}
            svg={<SvgContainer innerSvg={<CVCardSvg />} color={"#E7EBDB"} />}
            title="CV"
            btnText={studentPersonalInformation?.info && studentPersonalInformation?.info?.cv?   "Tamamlandı":"Natamam"}
            activateStatus={checkStudentApply || studentPersonalInformation?.info && studentPersonalInformation?.info?.cv}
          />  

        </div>
        {/* {
          studentExams !=""?
          <div className='flex justify-center mt-[30px]'>
          <button className='bg-blue-700 text-white text-[12px] p-[3px_6px] font-semibold rounded-[5px]'onClick={this.rematchBtn.bind(this, user?.id)}>Update available Programs</button>
        </div>:null
        } */}
        <div className='flex justify-center mt-[30px]'>
          <button className='bg-blue h-[48px] pl-[10px] pr-[10px]  text-[20px] rounded-[5px] text-white font-regular' onClick={this.rematchBtn.bind(this, user?.id)}>Yenidən yoxla və göstər</button>
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  studentPersonalInformation: state.Data.studentPersonalInformation,
  edubackground: state.Data.edubackground,
  studyDestination: state.Data.studyDestination,
  studentExams: state.Data.studentExams,
  checkStudentApply: state.Data.checkStudentApply,
});
const mapDispatchToProps = {changeStateValue, getSingleData, getEducationBackground, getPersonalInformation, getStudyDestination, getStudentExams, getCheckStudentApply, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentInfo))


