import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Buttons from '../../components/Buttons'
import { changeStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import { getGeneralSch, getUniversities } from '../../actions/MainAction'
import GeneralSchModal from '../../components/modals/GeneralSchModal'
import Card1 from '../../components/cards/Card1'

export class GeneralScholarships extends Component {
    componentDidMount(){
        this.props.getGeneralSch();
    }

    getDisciplines(data){
        let arr = [];
        for(const item of data){
            arr.push({
                label: item?.discipline?.name,
                value: item?.discipline_id
            })
        }
        return arr;
    }
    getUniversities(data){
        let arr = [];
        for(const item of data){
            arr.push({
                label: item?.university?.name,
                value: item?.university_id
            })
        }
        return arr;
    }
    openModal=(data="") => {
        this.props.changeStateValue({
            name:"generalSchModal",
            value:{
                position: true,
                id: data?.id?data?.id:'',
                country_id: data?.country_id?data?.country_id:'',
                name: data?.name?data?.name:'',
                name_tr: data?.name_tr?data?.name_tr:'',
                name_en: data?.name_en?data?.name_en:'',
                name_ru: data?.name_ru?data?.name_ru:'',
                description: data?.description?data?.description:'',
                description_tr: data?.description_tr?data?.description_tr:'',
                description_en: data?.description_en?data?.description_en:'',
                description_ru: data?.description_ru?data?.description_ru:'',
                disciplines: data?.discipline? this.getDisciplines(data?.discipline):[],
                universities: data?.university? this.getUniversities(data?.university):[],
            }
        })

        if(data?.country_id){
            this.props.getUniversities("", "", "all", data?.country_id);
        }
    }
  render() {
    const {generalScholarship, generalSchModal} = this.props;
    // console.log(generalScholarship)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="General Scholarship" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a Sch", value:0}]} selected={0} />
            </div>
            <div className='grid-full-250 mt-[30px]'>
              {
                generalScholarship?.map((data, i)=>{
                  return(
                    <Card1
                      key={i}
                      name={data?.name}
                      country={data?.country?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`general-scholarship/${data?.id}`}
                    />
                  )
                })
              }
            </div>
           {
            generalSchModal?.position? 
            <GeneralSchModal />:null
           }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    generalScholarship: state.Data.generalScholarship,
    generalSchModal: state.Data.generalSchModal,
});
const mapDispatchToProps = {changeStateValue, getGeneralSch,getUniversities}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralScholarships)
