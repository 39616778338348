import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { getCountries, getDisciplines, getGeneralSch, getUniversities, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Input from './components/Input';
import TextEditor from './components/TextEditor';
import Header from './components/Header';
import { getDropValue } from '../../actions/getOtherFunctions';
import DropSearch from './components/DropSearch';
import MultiSelect from './components/MultiSelect';
import { mixinAlert } from '../../actions/SweetAlerts';

export class GeneralSchModal extends Component {
    state={
        btnDisabled:false
    }
    componentDidMount(){
        this.props.getCountries(5);
        // this.props.getUniversities("", "", "all")
        this.props.getDisciplines("", "", "all")
        .then(resp=>{
            if(this.props.generalSchModal?.id =="" && this.props.generalSchModal.disciplines.length == 0){
                this.props.changeStateValue({
                    name:"generalSchModal.disciplines",
                    value:getDropValue(this.props.disciplines, "name","id")
                })
            }
        })
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"generalSchModal"})
    }

    onSelectCountry=(id)=>{
        this.props.getUniversities("", "", "all", id);
        this.props.changeStateValue({name:"generalSchModal.universities", value:[]})
    }
    getArrayValue(data){
        let arr = [];
        for(const item of data){
            arr.push(item?.value)
        }
        return arr;
    }
    saveBtn=(data)=>{
        if(data?.name !=""){

            let formData = new FormData();

            for(const property in data){

                formData.append(property, data[property])

            }
            if(data?.disciplines?.length !=0){
                formData.append("disciplines", this.getArrayValue(data?.disciplines));
            }
            if(data?.universities?.length !=0){
                formData.append("universities", this.getArrayValue(data?.universities));
            }

            let url = "general-scholarship";

            if(data?.id && data?.id !=""){

                url = `general-scholarship/${data?.id}`;
                
                formData.append("_method", "put");

            }

            this.props.insertNewData(url, formData)
            .then(resp=>{
                if(resp == "success"){
                    mixinAlert("success", "Data saved successfully");
                    this.closeModal();
                    this.props.getGeneralSch();
                }else{
                    mixinAlert("error", "something went wrong");
                }
            })
        }
    }
  render() {

    const {generalSchModal, disciplines, universities, countries} = this.props;
    const {btnDisabled} = this.state;
    console.log(universities)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow'>
                <Header title={generalSchModal?.id == ""? "Add data":"Edit data"} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] overflow-auto '>
                    <div  className='grid grid-cols-2 gap-[20px]'>
                        {generalSchModal?.name || generalSchModal?.name == "" ? <Input required name="generalSchModal.name" value={generalSchModal?.name} title={'Name'}  />:null}
                        {generalSchModal?.name_en || generalSchModal?.name_en == "" ? <Input  name="generalSchModal.name_en" value={generalSchModal?.name_en} title={'Name en'}  />:null}
                        {generalSchModal?.name_ru || generalSchModal?.name_ru == "" ? <Input  name="generalSchModal.name_ru" value={generalSchModal?.name_ru} title={'Name ru'}  />:null}
                        {generalSchModal?.name_tr || generalSchModal?.name_tr == "" ? <Input  name="generalSchModal.name_tr" value={generalSchModal?.name_tr} title={'Name tr'}  />:null}

                        {generalSchModal?.country_id || generalSchModal?.country_id == "" ? <DropSearch  name="generalSchModal.country_id" selectData={this.onSelectCountry.bind(this)}  value={generalSchModal.country_id} placeholder="Select a Country" options={getDropValue(countries, "name","id")} title="Select a Country" /> :null}
                        
                        { generalSchModal?.country_id  !=""?<MultiSelect selected={generalSchModal.universities} placeholder={"Select a university"} options={getDropValue(universities, "name","id")} name="generalSchModal.universities" title={"Select a university"} />:null}
                        {generalSchModal?.disciplines? <MultiSelect selected={generalSchModal.disciplines} placeholder={"Select a discipline"} options={getDropValue(disciplines, "name","id")} name="generalSchModal.disciplines" title={"Select a discipline"} />:null}
                    
                    </div>

                    <div className='mt-[20px]'>
                        {
                            generalSchModal?.description || generalSchModal?.description == ""? <TextEditor title="Description" name="generalSchModal.description" value={generalSchModal?.description} />:null
                        }
                        {
                            generalSchModal?.description_en || generalSchModal?.description_en == ""? <TextEditor title="Description en" name="generalSchModal.description_en" value={generalSchModal?.description_en} />:null
                        }
                        {
                            generalSchModal?.description_ru || generalSchModal?.description_ru == ""? <TextEditor title="Description ru" name="generalSchModal.description_ru" value={generalSchModal?.description_ru} />:null
                        }
                        {
                            generalSchModal?.description_tr || generalSchModal?.description_tr == ""? <TextEditor title="Description tr" name="generalSchModal.description_tr" value={generalSchModal?.description_tr} />:null
                        }
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Bağla</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,generalSchModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Yadda saxla</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    generalSchModal: state.Data.generalSchModal,
    countries: state.Data.countries,
    universities: state.Data.universities,
    disciplines: state.Data.disciplines,
});
const mapDispatchToProps = {changeStateValue, resetStateValue,insertNewData, getCountries, getUniversities, getDisciplines, getGeneralSch}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralSchModal)
