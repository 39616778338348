import RecUniSvg from "../public_components/svg/RecUniSvg";
import AppUniSvg from "../svg/AppUniSvg";
import BookingSvg from "../svg/BookingSvg";
import CountrySvg from "../svg/CountrySvg";
import DashSvg from "../svg/DashSvg";
import InfoSvg from "../svg/InfoSvg";
import MesageSvg from "../svg/MesageSvg";
import NavApply2Svg from "../svg/NavApply2Svg";
import NavApplySvg from "../svg/NavApplySvg";
import NavMessageSvg from "../svg/NavMessageSvg";
import NavScheduleSvg from "../svg/NavScheduleSvg";
import NavStudentSvg from "../svg/NavStudentSvg";
import NavTeacherSvg from "../svg/NavTeacherSvg";
import WishListSvg from "../svg/WishListSvg";

export const adminRoles = [
    {
        part: "",
        pages: [
            {
                title:"Dashboard",
                path:"/",
                svg: <DashSvg />
            }
        ],
        line:false
    },
   
    {
       part: "CONTENTS",
       pages: [
            {
                title: "Countries",
                path:"/admin-countries",
                svg: <CountrySvg />
            },
            {
                title: "States",
                path:"/admin-states",
                svg: <CountrySvg />
            },
            {
                title: "Cities",
                path:"/admin-cities",
                svg: <CountrySvg />
            },
            {
                title: "University type",
                path:"/admin-uni-type",
                svg: <CountrySvg />
            },
            {
                title: "Universities",
                path:"/admin-universities",
                svg: <CountrySvg />
            },
            {
                title: "Disciplines",
                path:"/admin-discipline",
                svg: <CountrySvg />
            },
            {
                title: "Education degree",
                path:"/admin-edu-degree",
                svg: <CountrySvg />
            },
            {
                title: "Language",
                path:"/admin-language",
                svg: <CountrySvg />
            },
            {
                title: "Programs",
                path:"/admin-programs",
                svg: <CountrySvg />
            },
            {
                title: "XTM",
                path:"/admin-xtm",
                svg: <CountrySvg />
            },
            {
                title: "Content Manager",
                path:"/admin-content-manager",
                svg: <CountrySvg />
            },
            {
                title: "Exams",
                path:"/admin-exams",
                svg: <CountrySvg />
            },
            {
                title: "High School Exam",
                path:"/admin-high-school-exams",
                svg: <CountrySvg />
            },
            {
                title: "Apply Requirements",
                path:"/admin-apply-requirements",
                svg: <CountrySvg />
            },
            {
                title: "Expences Categories",
                path:"/add-expences-categories",
                svg: <CountrySvg />
            },
            {
                title: "Currency",
                path:"/admin-currencies",
                svg: <CountrySvg />
            },
            {
                title: "Costumer review",
                path:"/admin-costumer-review",
                svg: <CountrySvg />
            },
            {
                title: "Blog",
                path:"/admin-blogs",
                svg: <CountrySvg />
            },
            {
                title: "Booking info",
                path:"/admin-booking-info",
                svg: <CountrySvg />
            },
            {
                title: "Bookings",
                path:"/admin-booking",
                svg: <CountrySvg />
            },
            {
                title: "Subjects",
                path:"/admin-subject",
                svg: <CountrySvg />
            },
            {
                title: "Ranking Applies",
                path:"/admin-ranking-applies",
                svg: <CountrySvg />
            },
            {
                title: "CELT az Applies",
                path:"/admin-celt-az-applies",
                svg: <CountrySvg />
            },
            {
                title: "Add Auto prog",
                path:"/admin-prog-auto",
                svg: <CountrySvg />
            },
            // {
            //     title: "Add Auto rank",
            //     path:"/admin-rank-auto",
            //     svg: <CountrySvg />
            // },
            {
                title: "City rankings",
                path:"/admin-city-ranking",
                svg: <CountrySvg />
            },
            // {
            //     title: "University rankings",
            //     path:"/admin-university-ranking",
            //     svg: <CountrySvg />
            // },
            // {
            //     title: "Employee rankings",
            //     path:"/admin-employee-ranking",
            //     svg: <CountrySvg />
            // },
            {
                title: "Subject rankings",
                path:"/admin-subject-ranking",
                svg: <CountrySvg />
            },
            // {
            //     title: "Top 300 rankings",
            //     path:"/admin-top300-ranking",
            //     svg: <CountrySvg />
            // },
            // {
            //     title: "Top 20 rankings",
            //     path:"/admin-top20-ranking",
            //     svg: <CountrySvg />
            // },
            {
                title: "Application Trackings",
                path:"/admin-application-track",
                svg: <CountrySvg />
            },
            {
                title: "Accomadation Trackings",
                path:"/admin-accomadation-track",
                svg: <CountrySvg />
            },
            {
                title: "Visa Trackings",
                path:"/admin-visa-track",
                svg: <CountrySvg />
            },
            {
                title: "Advertising",
                path:"/admin-advertising",
                svg: <CountrySvg />
            },
            {
                title: "Add Auto prog",
                path:"/admin-prog-auto",
                svg: <CountrySvg />
            },
            {
                title: "Uni about",
                path:"/admin-uni-about",
                svg: <CountrySvg />
            },
            {
                title: "Country Scholarship",
                path:"/admin-country-scholarship",
                svg: <CountrySvg />
            },
            // {
            //     title: "SEO",
            //     path:"/admin-seo",
            //     svg: <CountrySvg />
            // },
            {
                title: "Empty Universities",
                path:"/admin-empty-uni",
                svg: <CountrySvg />
            },
            {
                title: "General Scholarship",
                path:"/admin-general-sch",
                svg: <CountrySvg />
            },
       ],
       line:true
    },
]
export const xtmRoles = [
    {
        part: "",
        pages: [
            {
                title:"Dashboard",
                path:"/",
                svg: <DashSvg />
            },
            {
                title: "Managers",
                path:"/managers",
                svg: <CountrySvg />
            },
            {
                title: "Təhsil Nümayəndəsi",
                path:"/teachers",
                svg: <NavTeacherSvg />
            },
            {
                title: "Tələbələr",
                path:"/students",
                svg: <NavStudentSvg />
            },
            {
                title: "Müraciət edənlər",
                path:"/admin-ranking-applies",
                svg: <NavApplySvg />
            },
            {
                title: "CELT Az müraciətləri",
                path:"/admin-celt-az-applies",
                svg: <NavApply2Svg />
            },
            {
                title: "Randevular",
                path:"/admin-booking",
                svg: <BookingSvg />
            },
        ],
        line:false
    }
]
export const managerRoles = [
    {
        part: "",
        pages: [
            {
                title:"Ana səhifə",
                path:"/",
                svg: <DashSvg />
            },
            {
                title: "Təhsil Nümayəndəsi",
                path:"/teachers",
                svg: <NavTeacherSvg />
            },
            {
                title: "Müraciət edənlər",
                path:"/admin-ranking-applies",
                svg: <NavApplySvg />
            },
            {
                title: "CELT Az müraciətləri",
                path:"/admin-celt-az-applies",
                svg: <NavApply2Svg />
            },
            {
                title: "Randevular",
                path:"/admin-booking",
                svg: <BookingSvg />
            },
        ],
        line:false
    },
]
export const teacherRoles = [
    {
        part: "",
        pages: [
            {
                title:"Ana Səhifə",
                path:"/",
                svg: <DashSvg />
            },
            {
                title: "Tələbələr",
                path:"/students",
                svg: <NavStudentSvg />
            },
            {
                title: "Mesajlar",
                path:"/staff-messages",
                svg: <NavMessageSvg />
            },
        ],
        line:false
    },
   
    // {
    //    part: "CONTENTS",
    //    pages: [
            // {
            //     title: "Students",
            //     path:"/students",
            //     svg: <CountrySvg />
            // },
    //    ],
    //    line:true
    // },
]
export const studentRoles = [
    {
        part: "",
        pages: [
            {
                title:"Ana Səhifə",
                path:"/",
                svg: <DashSvg />
            },
            {
                title: "Təklif olunanlar",
                path:"/available-programs",
                svg: <RecUniSvg />
            },
            {
                title: "Müraciət olunanlar",
                path:"/applied-programs",
                svg: <AppUniSvg />
            },
            {
                title: "Təhsil Profiliniz",
                path:"/info",
                svg: <InfoSvg />
            },
            {
                title: "Mesajlaşma",
                path:"/message",
                svg: <MesageSvg />
            },
            {
                title: "Proqram sorğusu",
                path:"/wish-list",
                svg: <WishListSvg />
            },
            {
                title: "Randevu",
                path:"/booking",
                svg: <NavScheduleSvg />
            },
        ],
        line:false
    },
   
    // {
    //    part: "CONTENTS",
    //    pages: [
    //         {
    //             title: "Available programs",
    //             path:"/available-programs",
    //             svg: <CountrySvg />
    //         },
    //         {
    //             title: "Applied programs",
    //             path:"/applied-programs",
    //             svg: <CountrySvg />
    //         },
    //         {
    //             title: "Info",
    //             path:"/info",
    //             svg: <CountrySvg />
    //         },
    //         {
    //             title: "Message",
    //             path:"/message",
    //             svg: <CountrySvg />
    //         },
    //         {
    //             title: "Wish List",
    //             path:"/wish-list",
    //             svg: <CountrySvg />
    //         },
    //    ],
    //    line:true
    // },
]

export const contentRoles = [
    {
        part: "",
        pages: [
            {
                title: "States",
                path:"/admin-states",
                svg: <CountrySvg />
            },
            {
                title: "Cities",
                path:"/admin-cities",
                svg: <CountrySvg />
            },
            {
                title: "Universities",
                path:"/admin-universities",
                svg: <CountrySvg />
            },
            {
                title: "Programs",
                path:"/admin-programs",
                svg: <CountrySvg />
            }, 
            {
                title: "Country Scholarship",
                path:"/admin-country-scholarship",
                svg: <CountrySvg />
            },
            {
                title: "Not fee Universities",
                path:"/content-not-fee",
                svg: <CountrySvg />
            },
        ]
    }
]