import React, { Component } from 'react'
import BookingCalendarSection from '../public_pages/sections/BookingCalendarSection'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';

export class StudentBooking extends Component {
    componentDidMount(){
        if(this.props.user !=""){
            this.props.changeStateValue({  name:"bookingUserInfo.name",  value: this.props.user?.full_name  });
            this.props.changeStateValue({  name:"bookingUserInfo.email",  value: this.props.user?.email  });
            this.props.changeStateValue({  name:"bookingUserInfo.phone_number",  value: this.props.user?.phone_number  });
            this.props.changeStateValue({  name:"bookingUserInfo.day",  value: ""  });
            this.props.changeStateValue({  name:"bookingUserInfo.time",  value: ""  });
        }
    }
  render() {
    return (
        <div className='pt-[40px]'>
            {/* <SectionHeader text1={this.props.language?.section_title6} text2="" /> */}
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
                <div className='bg-white p-[20px] rounded-[5px]'>
                    <BookingCalendarSection />
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentBooking)
