import React, { Component } from 'react'
import { getPublicDisciplines } from '../../../actions/PublicActions';
import { connect } from 'react-redux';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { changeStateValue } from '../../../redux/MainReducer';
import SelectBtn from '../../../components/apply/SelectBtn';
import { getTranslateLanguage } from '../../../actions/getOtherFunctions';

export class DisciplinePage extends Component {
    state={
        page:1,
        contRef: React.createRef()
    }
    componentDidMount(){
        this.props.getPublicDisciplines('', "all")
    }
    selectDiscpline=(id)=>{
        let disciplines = [...this.props.applyDetails?.disciplines];
        const findIndex = disciplines.findIndex(x=>x == id);
        if(findIndex === -1){
          if(disciplines.length <5){
            disciplines.push(id)
            this.props.changeStateValue({
              name: "applyDetails.disciplines",
              value: disciplines
            })
          }else{
            mixinAlert("info", "5 dən çox ixtisas seçə bilməzsiniz.");
          }
          
        }else{
          disciplines.splice(findIndex, 1);
          this.props.changeStateValue({
            name: "applyDetails.disciplines",
            value: disciplines
          })
        }
       
       
    }
    loadMoreBtn=(page, elementRef)=>{
      this.setState({page:page});
      setTimeout(()=>{
        if (elementRef.current) {
          elementRef.current.scrollTop = elementRef.current.scrollHeight;
        }
      }, 300)
     }
  render() {
    const {publicDisciplines, applyDetails} = this.props;
    const {page, contRef} = this.state;
    return (
        <div>
        <div ref={contRef} className='grid grid-cols-2 scroll-smooth gap-[25px] mt-[40px] max-[640px]:grid-cols-1 max-h-[30vh] overflow-auto'>
          {
            [...publicDisciplines]?.sort((a, b) => b.programs_count - a.programs_count).map((data, i)=>{
              if(i<page*4){
                return(
                  <SelectBtn key={i} 
                    title={getTranslateLanguage(this.props.lang_id, "name", data)} 
                    onClick={this.selectDiscpline.bind(this, data?.id)}
                    check={applyDetails?.disciplines.includes(data?.id)} />
                    
                )
              }
              
            })
          }
        </div>
        <div className='flex justify-end items-center mt-[27px] '>
        {
          Math.ceil(publicDisciplines?.length/4) > page?
          <button onClick={this.loadMoreBtn.bind(this, page+1, contRef)}  className='text-[#1E398F] font-medium text-[14px]'>
            {this.props.language?.more_show}...
          </button>:
          <button  onClick={this.loadMoreBtn.bind(this, 1, contRef)} className='text-[#1E398F] font-medium text-[14px]'>
            {this.props.language?.less_show}...
          </button>
        }
        </div> 
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    publicDisciplines: state.Data.publicDisciplines,
    applyDetails: state.Data.applyDetails,
    language: state.Data.language,
    lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getPublicDisciplines, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DisciplinePage)
